<template>
  <CRow>
    <CCol col="12" xl="12">
      <CCard>
        <CCardHeader>
          <CRow>
              <CCol md="7">
                    <h4>Remittance Pending</h4> 
              </CCol>              
              <CCol md="5">
                   <CInput label="Search" v-model="filter" @change="getFilterData" placeholder="Type to Search" horizontal />                   
              </CCol>              
          </CRow>
        </CCardHeader>
        <CCardBody> 

            <CRow>              
                 <b-col sm="6">
                  <input type="checkbox" id="cbstatus" name="cbstatus"  
                  @change="onModifyClick"  style="width: 20px; height: 20px;" 
                  v-model="cbstatus"> &nbsp; ERROR REMITTANCE LIST                         
                </b-col>
            </CRow><br/>                       

         <CRow>
            <CCol col="12" class="text-left">                   
               <CAlert v-bind:color="color" v-if="seen" id="alert">{{ msg }}</CAlert>
            </CCol> 
         </CRow>  

         <div class="text-center">
            <b-spinner variant="success" label="Spinning" v-if="spinner" ></b-spinner>
         </div>

        <div class="table-responsive">
          <b-table striped hover  :items="items" :fields="fields" v-model="list" sort-by="createDate" :sort-desc="true" :current-page="currentPage" :per-page="perPage" :filter="filter"> 

              <template v-slot:cell(createDate)="{ item }"> 
                {{ item.createDate | dateWithTime }}
              </template>

              <template v-slot:cell(sendAmount)="{ item }"> 
                {{ item.sendAmount | setDecPlaces }}
              </template>

              <template v-slot:cell(paymentMethod)="{ item }"> 
                  <span v-if="item.paymentMethod === 'MANUAL'">
                      {{ item.paymentMethod }}
                  </span>
                  <span v-else>
                       WALLET
                  </span>               
              </template>

              <template v-slot:cell(trType)="{ item }"> 
                  {{ item.paymentMethod | setlabel }}
              </template>

              <template v-slot:cell(actions)="{ item }"> 
                <b-dropdown variant="info" text="Actions" size="md"> 
                    <router-link to="#" exact v-on:click.native="ActionCancel(item.providerTxnId)" tag="b-dropdown-item" > Cancel </router-link>
                    <router-link to="#" exact v-on:click.native="ActionComplete(item.providerTxnId)" tag="b-dropdown-item" > Complete </router-link>
                </b-dropdown> 
              </template>
          </b-table>
        </div>

        <div>
          <b-row>
            <b-col sm="3">
               <b-pagination size="md" :total-rows="this.items.length" :per-page="perPage" v-model="currentPage" />
            </b-col>       
            <b-col sm="9">
               <b-form-select :options="[{text:10,value:10},{text:20,value:20},{text:50,value:50},{text:100,value:100}]" v-model="perPage" style="width:100px;">
               </b-form-select>
            </b-col>
          </b-row>      
        </div>
          
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
import TransactService from '@/api/TransactService.js';

 export default {
    name: "pendingremitt",
    components: {},     
    data() {
      return {
        cbstatus : false,  
        type : "good",
        spinner:false,
        list:[],
        columns : [],
        data : [],
        items:[],             
        fields: [ 
          {
            key: 'createDate',
            label : 'Tr Date',
            sortable: true
          },   
          {
            key: 'movementRefId',
            label : 'Tr Reference',
            sortable: true
          },         
          {
            key: 'mobileNumber',
            label : 'Mobile Number',
            sortable: true
          },          
          {
            key: 'senderCurrency',
            label : 'Currency',
            sortable: true
          },  
          {
            key: 'sendAmount',
            label : 'Amount',
            sortable: true
          },
          {
            key: 'provider',
            label : 'Provider',
            sortable: true
          },  
          {
            key: 'paymentMethod',
            label : 'Payment Method',
            sortable: true
          },
          {
            key: 'trType',
            label : 'Transaction Type',
            sortable: true
          },
          {
            key: 'movementStatus',
            label : 'Status',
            sortable: true
          },
          {            
            key: 'actions',
            label: 'Actions'         
          }                   
        ],         
        currentPage: 1,
        perPage: 10,
        filter: null,
        seen:false,
        msg :'',
        color:''
      };
    },    
    filters: {
        upper(value){
          if(!value) return ''
          value = value.toString()
          return value.toUpperCase()
        },
        lower(value){
           if(!value) return ''
           value = value.toString()
           return value.toLowerCase()        
        },
        dateSubstr(value){
           if(!value) return ''
           value = value.toString()
           return value.substring(0,10)            
        },        
        IdSubstr(value){
           if(!value) return ''
           value = value.toString()
           return value.substring(0,5) + '...'            
        },
        dateWithTime(value){
           if(!value) return ''
           var temp = value.toString().split(".");
           value = temp[0].replace("T"," ");
           return value;            
        },
        setDecPlaces(value){
            if(!value) return 0 
            return value.toFixed(3);
        },
        formatNum(value){ 
           if(!value) return 0  
           return value.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")          
        },
        setlabel(value){ 
          switch(value) {
            case "WALLET":
              var label = "WALLET REMIT";
              break;
            case "MANUAL":
              var label = "MANUAL REMIT";
              break;
            case "WALLET_IDR":
              var label = "IDR PAYNOW";
              break;
            case "WALLET_PHP":
              var label = "PHP PAYNOW";
              break;
            case "WALLET_IDR_PROXY":
              var label = "IDR PROXY PAYNOW";
              break;
            default:
              var lebel = "NONE";
          }

          return label;                    
        }
    },
    created () {   
       this.spinner = true;     
       this.getRemittaceErrorList(this.type); 
    },
    methods: { 

      getFilterData(){          
         this.data  = this.list;
      },

      onModifyClick(){
          this.spinner = true;
          var status   = this.cbstatus ? "error" : "good";  
          this.type    = status;     //console.log(status);
          this.getRemittaceErrorList(status);
      },

      ActionComplete(id){
          var result = confirm("Are you sure want to Complete Transaction ?");
          if (result) { this.completeRemittaceList(id); } 
      }, 
       
      ActionCancel(id){
         var result = confirm("Are you sure want to Cancel Transaction ?");
         if (result) { this.cancelRemittaceList(id); }         
      },  

      cancelRemittaceList: function(ProTxnId) {
        TransactService.cancelRemittaceErrorList(ProTxnId).then(resp => {
            if(resp.message==="SUCCESS"){
              this.msg   = "Action Cancel Success!";  
              var status = (this.type === "error") ? "error" : "good";                         
              this.getRemittaceErrorList(status);
            } else {
              this.msg = !resp.data.message ? "Action Cancel Failed!" : resp.data.message; 
              this.color = 'danger'; this.seen  = true;              
            }
            alert(this.msg);
            setTimeout( () =>  this.seen=false , 5000);         
        }, error => {
          this.spinner = false;
        });
      },

      completeRemittaceList: function(ProTxnId) {
        TransactService.completeRemittaceErrorList(ProTxnId).then(resp => {
            if(resp.message==="SUCCESS"){ 
              this.msg   = "Action Complete Success!";  
              var status = (this.type === "error") ? "error" : "good";            
              this.getRemittaceErrorList(status);
            } else {
              this.msg   = !resp.data.message ? "Action Complete Failed!" : resp.data.message; 
              this.color = 'danger'; this.seen  = true;              
            }
            alert(this.msg);
            setTimeout( () =>  this.seen=false , 5000);    
        }, error => {
          this.spinner = false;
        });
      },    
     
      getRemittaceErrorList: function(status) { 
         TransactService.getRemittaceErrorList(status).then(resp => { //console.log(resp);
            this.items = resp; this.spinner = false;
         }, error => { this.spinner = false; });
      }      

    }
 }; 
</script>

