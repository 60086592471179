var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('CRow',[_c('CCol',{attrs:{"col":"12","xl":"12"}},[_c('CCard',[_c('CCardHeader',[_c('CRow',[_c('CCol',{attrs:{"md":"7"}},[_c('h4',[_vm._v("Remittance Pending")])]),_c('CCol',{attrs:{"md":"5"}},[_c('CInput',{attrs:{"label":"Search","placeholder":"Type to Search","horizontal":""},on:{"change":_vm.getFilterData},model:{value:(_vm.filter),callback:function ($$v) {_vm.filter=$$v},expression:"filter"}})],1)],1)],1),_c('CCardBody',[_c('CRow',[_c('b-col',{attrs:{"sm":"6"}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.cbstatus),expression:"cbstatus"}],staticStyle:{"width":"20px","height":"20px"},attrs:{"type":"checkbox","id":"cbstatus","name":"cbstatus"},domProps:{"checked":Array.isArray(_vm.cbstatus)?_vm._i(_vm.cbstatus,null)>-1:(_vm.cbstatus)},on:{"change":[function($event){var $$a=_vm.cbstatus,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.cbstatus=$$a.concat([$$v]))}else{$$i>-1&&(_vm.cbstatus=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.cbstatus=$$c}},_vm.onModifyClick]}}),_vm._v("   ERROR REMITTANCE LIST ")])],1),_c('br'),_c('CRow',[_c('CCol',{staticClass:"text-left",attrs:{"col":"12"}},[(_vm.seen)?_c('CAlert',{attrs:{"color":_vm.color,"id":"alert"}},[_vm._v(_vm._s(_vm.msg))]):_vm._e()],1)],1),_c('div',{staticClass:"text-center"},[(_vm.spinner)?_c('b-spinner',{attrs:{"variant":"success","label":"Spinning"}}):_vm._e()],1),_c('div',{staticClass:"table-responsive"},[_c('b-table',{attrs:{"striped":"","hover":"","items":_vm.items,"fields":_vm.fields,"sort-by":"createDate","sort-desc":true,"current-page":_vm.currentPage,"per-page":_vm.perPage,"filter":_vm.filter},scopedSlots:_vm._u([{key:"cell(createDate)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("dateWithTime")(item.createDate))+" ")]}},{key:"cell(sendAmount)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("setDecPlaces")(item.sendAmount))+" ")]}},{key:"cell(paymentMethod)",fn:function(ref){
var item = ref.item;
return [(item.paymentMethod === 'MANUAL')?_c('span',[_vm._v(" "+_vm._s(item.paymentMethod)+" ")]):_c('span',[_vm._v(" WALLET ")])]}},{key:"cell(trType)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("setlabel")(item.paymentMethod))+" ")]}},{key:"cell(actions)",fn:function(ref){
var item = ref.item;
return [_c('b-dropdown',{attrs:{"variant":"info","text":"Actions","size":"md"}},[_c('router-link',{attrs:{"to":"#","exact":"","tag":"b-dropdown-item"},nativeOn:{"click":function($event){return _vm.ActionCancel(item.providerTxnId)}}},[_vm._v(" Cancel ")]),_c('router-link',{attrs:{"to":"#","exact":"","tag":"b-dropdown-item"},nativeOn:{"click":function($event){return _vm.ActionComplete(item.providerTxnId)}}},[_vm._v(" Complete ")])],1)]}}]),model:{value:(_vm.list),callback:function ($$v) {_vm.list=$$v},expression:"list"}})],1),_c('div',[_c('b-row',[_c('b-col',{attrs:{"sm":"3"}},[_c('b-pagination',{attrs:{"size":"md","total-rows":this.items.length,"per-page":_vm.perPage},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1),_c('b-col',{attrs:{"sm":"9"}},[_c('b-form-select',{staticStyle:{"width":"100px"},attrs:{"options":[{text:10,value:10},{text:20,value:20},{text:50,value:50},{text:100,value:100}]},model:{value:(_vm.perPage),callback:function ($$v) {_vm.perPage=$$v},expression:"perPage"}})],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }